.banner_area {
    background-image: url('/fileadmin/pics/slider/FBSA-gefecht.jpg');

    .banner_inner {
        h1, .h1 {
            font-size: 48px;
            color: #fff;
            font-family: $mon;
            font-weight: bold;
            letter-spacing: .96px;
            padding-bottom: 14px;

            @media (max-width:768px) {
                font-size: 28px;
            }
            @media (max-width:480px) {
                font-size: 24px;
            }
        }

        ul li a::before {
            font-family: "Font Awesome 5 Free";
            font-weight: 900;
            content: "\f105";
        }
    }
}
