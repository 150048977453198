// -========================================
// = plugin.tx_frontend._CSS_DEFAULT_STYLE
// -========================================
$mediaMarginDefault: 1rem;
$mediaColumnSpacing: .25rem;

// Column margin only for text and media
.frame-type-textmedia .columns {
    margin-bottom: $mediaMarginDefault;
}

.ce-align-left { text-align: left; }
.ce-align-center { text-align: center; }
.ce-align-right { text-align: right; }

.ce-table td, .ce-table th { vertical-align: top; }

.ce-textpic, .ce-image, .ce-nowrap .ce-bodytext, .ce-gallery, .ce-row, .ce-uploads li, .ce-uploads div { overflow: hidden; }

// outcommented two lines, because of problems with responsive embedding videos
//test: .ce-left .ce-gallery, .ce-column { float: left; }
.ce-left .ce-gallery { float: left; }

// outcommented two lines, because of problems with responsive embedding videos
//test:.ce-center .ce-outer { position: relative; float: right; right: 50%; }
//test:.ce-center .ce-inner { position: relative; float: right; right: -50%; }
.ce-right .ce-gallery { float: right; }

.ce-gallery figure { display: table; margin: 0; }
.ce-gallery figcaption { display: table-caption; caption-side: bottom; }
.ce-gallery img { display: block; }
.ce-gallery iframe { border-width: 0; }
.ce-border img,
.ce-border iframe {
    border: 1px solid #e3e3e3;
    padding: 0;
}

.ce-intext.ce-right .ce-gallery, .ce-intext.ce-left .ce-gallery, .ce-above .ce-gallery {
  margin-bottom: $mediaMarginDefault;
}
.ce-intext.ce-right .ce-gallery { margin-left: $mediaMarginDefault; }
.ce-intext.ce-left .ce-gallery { margin-right: $mediaMarginDefault; }
.ce-below .ce-gallery { margin-top: $mediaMarginDefault; }

.ce-column { margin-right: $mediaColumnSpacing; }
.ce-column:last-child { margin-right: 0; }

//  .ce-row { margin-bottom: {$styles.content.textmedia.rowSpacing}px; }
.ce-row:last-child { margin-bottom: 0; }

.ce-above .ce-bodytext { clear: both; }

//    .ce-intext.ce-left ol, .ce-intext.ce-left ul { padding-left: 40px; overflow: auto; }

//    /* Headline */
//    .ce-headline-left { text-align: left; }
//    .ce-headline-center { text-align: center; }
//    .ce-headline-right { text-align: right; }

//    /* Uploads */
//    .ce-uploads { margin: 0; padding: 0; }
//    .ce-uploads li { list-style: none outside none; margin: 1em 0; }
//    .ce-uploads img { float: left; padding-right: 1em; vertical-align: top; }
//    .ce-uploads span { display: block; }

//    /* Table */
//    .ce-table { width: 100%; max-width: 100%; }
//    .ce-table th, .ce-table td { padding: 0.5em 0.75em; vertical-align: top; }
//    .ce-table thead th { border-bottom: 2px solid #dadada; }
//    .ce-table th, .ce-table td { border-top: 1px solid #dadada; }
//    .ce-table-striped tbody tr:nth-of-type(odd) { background-color: rgba(0,0,0,.05); }
//    .ce-table-bordered th, .ce-table-bordered td { border: 1px solid #dadada; }

// Space
.frame-space-before-extra-small { margin-top: 1rem; }
.frame-space-before-small { margin-top: 2rem; }
.frame-space-before-medium { margin-top: 3rem; }
.frame-space-before-large { margin-top: 4rem; }
.frame-space-before-extra-large { margin-top: 5rem; }
.frame-space-after-extra-small { margin-bottom: 1rem; }
.frame-space-after-small { margin-bottom: 2rem; }
.frame-space-after-medium { margin-bottom: 3rem; }
.frame-space-after-large { margin-bottom: 4rem; }
.frame-space-after-extra-large { margin-bottom: 5rem; }

// Frame
.frame-ruler-before:before { content: ''; display: block; border-top: 1px solid rgba(0,0,0,0.25); margin-bottom: 2em; }
.frame-ruler-after:after { content: ''; display: block; border-bottom: 1px solid rgba(0,0,0,0.25); margin-top: 2em; }
.frame-indent { margin-left: 15%; margin-right: 15%; }
.frame-indent-left { margin-left: 33%; }
.frame-indent-right { margin-right: 33%; }


.ce-gallery iframe {
    border-width: 0;
}

//.ce-gallery.video {
//    &, .ce-column {
//      float: none;
//    }
//    figure {
//      display: block;
//    }
//    .full-width & {
//        @include flex-grid-column(6, 0);
//        margin: 0 auto;
//    }
//}

// Responsive Images
img {
    max-width: 100%;
    height: auto;
//	width: auto;
}

// @fixme
.media.partner-logo-item img {
    max-width: none;
}
