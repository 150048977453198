.latest_blog_inner {
    margin-bottom: 2rem;
    .text_blog {
        min-height: 340px;
        padding: 15px 25px 5px;
    }
}

.main-content {
    .teaser-text, .text_blog, .image_blog, recent_item {
        a:link, a:visited, a:hover, a:active {
            text-decoration: none;
            color: $pfont;
        }
    }

    a.read_btn, a.read_all {
        &:link, &:visited, &:hover, &:active {
            color: $pfont;
            text-decoration: none;
        }
    }
}

h1.h2 {
    font-size: 36px;
    font-family: "Montserrat", sans-serif;
    font-weight: bold;
    color: #242424;
    display: inline-block;
    position: relative;
}

.news-list-view {
    margin-bottom: 2rem;
}

.news-single .footer {
    font-size: 1.3rem;
    color: lighten($pfont, .3);

    span {
        &::after {
            content: '|';
        }
        &:last-child::after {
            content: '';
        }
    }
}

.text_blog p {
    color: $pfont;
}

.image_blog a {
    img {
        height: auto;
    }
    &:hover img {
        filter: grayscale(100%);
    }
}

.image_blog .img_blog_text {
    bottom: 10px;
    left: 15px;

    text-shadow: 1px 1px #666;

    p {
        color: #fff;
    }

    a:hover {
        h3, h5 {
            color: $baseColorh;
        }
    }
}

// more images in news detail
.news-img-wrap {
    margin: 3rem 0;
    display: flex;
    //align-items: center;
    .outer {
        margin-right: 1rem;
        &:last-child {
            margin-right: 0;
        }
    }
    .news-list-view & {
        position: relative;
    }
}

.news-backlink-wrap {
    margin: 2rem 0;
}

.main-content .recent_widget a {
    text-decoration: none;
}

.categoryTree {
    list-style-type: circle;
    margin-left: 2rem;
    ul {
        font-size: 90%;
    }
}

.main-content ul.pagination {
    margin-left: 0;
}

.main-content ul.pagination li {
    float: left;

    &::before {
        display: none;
    }

    &.current {
        margin: 0 7px 0 0;
        padding: 0;
        border: none;
        background: transparent;
        height: 44px;
        width: 44px;
        text-align: center;
        line-height: 40px;
        border: 2px solid #d4d4d4;
        color: $dip;
        font-size: 18px;
        border-radius: 0 !important;
        font-family: $open;
        font-weight: bold;
    }
}
