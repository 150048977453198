.main-content {
    a {
        text-decoration: none;
    }
    a:link, a:visited {
        color: $baseColor;
    }
    a:hover {
        color: $baseColorh;
    }
    a:active {
        color: $baseColorh;
    }
    a.learn_btn:link, a.learn_btn:visited, a.learn_btn:active {
        color: #fff;
    }
    s {
        color: lighten($pfont, 20%);
    }

    ul {
        list-style: none;
        margin: 0 0 1rem 1rem;
        padding: 0;
        li::before {
            font-family: "Font Awesome 5 Free";
            font-weight: 900;
            content: "\f105";
            margin-right: 1rem;
        }
        .shariff-button {
            padding-left: 0;
            text-align: center;
            &::before {
                display: none;
            }
            a {
                color: #fff;
            }
            &.info a {
                color: $pfont;
            }
        }
    }
    .shariff ul {
        margin-left: 0;
    }
}



p {
    margin-bottom: 1rem;
}

.with-sidebar h2 {
    &::before {
        display: none;
    }
}

h3 {
    margin: 2rem 0;
    color: #242424;
}

.ce-uploads ul {
    list-style: none;
    margin: 0;
    padding-left: 0;
}

.ce-uploads li > a, .news-related-files li > a {
    &::before {
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        content: "\f15b";
        margin-right: 1rem;
        color: $pfont;
        text-decoration: none;
    }

    &.filetype-pdf::before {
        content: "\f1c1";
    }
    &.filetype-doc::before {
        content: "\f1c2";
    }
}
