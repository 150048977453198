/* Latest News Area css
============================================================================================ */
.latest_news_area{
    background: #f6f6f6;
    .left_title{
        padding-bottom: 50px;
    }
    .pull-right{
        .read_all{
            padding-top: 5px;
            transition: all 0.3s linear;
            &:hover{
                color: #e2a062;
                &:before,&:after{
                    background: #e2a062;
                }
            }
        }
    }
}
.latest_blog_inner{
    overflow: hidden;
}
.image_blog{
    position: relative;
    a{
        img{
            max-width: 100%;
        }
    }
    .img_blog_text{
        position: absolute;
        bottom: 35px;
        left: 40px;
        h5{
            color: #fff;
            font-family: $mon;
            font-size: 13px;
            letter-spacing: .26px;
            padding-bottom: 18px;
        }
        h3{
            font-size: 18px;
            line-height: 24px;
            font-family: $mon;
            font-weight: 600;
            color: #fff;
        }
    }
}
.text_blog{
    background: #fff;
    padding: 35px 40px 25px 40px;
    transition: all 0.3s linear;
    h5{
        font-size: 13px;
        color: #616161;
        font-family: $mon;
        letter-spacing: .26px;
        padding-bottom: 18px;
        @include transition;
    }
    h3{
        line-height: 24px;
        font-size: 18px;
        color: #282828;
        font-family: $mon;
        font-weight: 600;
    }
    p{
        font-size: 16px;
        line-height: 26px;
        font-family: $lato;
        color: #9b9b9b;
        padding: 30px 0px;
        @include transition;
    }
    .blog_user{
        border-top: 2px solid #ececec;
        padding-top: 15px;
        h4{
            font-size: 15px;
            color: #616161;
            font-family: $lato;
            letter-spacing: .30px;
            @include transition;
            a{
                color: $baseColor;
                @include transition;
            }
        }
    }
    &:hover{
        background: #e2a062;
        h5,p,h4,h4 a{
            color: #fff;
        }
    }
}
.client_slider{
    padding-top: 100px;
    .item{
        text-align: center;
        img{
            display: inline-block;
            width: auto;
            max-width: 100%;
            line-height: 62px;
        }
    }
}
.clients_slider_area{
    padding: 75px 0px;
    background: #fff;
    .client_slider{
        padding-top: 0px;
    }
}
/* End Latest News Area css
============================================================================================ */

/* latest_news_two css
============================================================================================ */
.latest_news_two{
    background: #232323;
    position: relative;
    z-index: 1;
    &:before{
        content: "";
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: url("../img/blog-bg.jpg") no-repeat scroll center 0;
        opacity: 0.15;
        position: absolute;
        background-attachment: fixed;
        z-index: -1;
    }
    .left_title{
        h2{
            color: #fff;
        }
    }
    .read_all{
        color: #e2a062;
        &:after{
            background: #6d553f;
        }
        &:before{
            background: #87684a;
        }
    }
}
/* latest_news_two css
============================================================================================ */

/* Blog Details Area css
============================================================================================ */
.blog_details_area{
    .blog_details_inner{
        padding-right: 30px;
    }
}
.blog_item_inner{
    padding-right: 30px;
    .blog_item{
        margin-bottom: 80px;
        &:last-child{
            margin-bottom: 0px;
        }
    }
}
.blog_item{
    .blog_img{
        img{
            max-width: 100%;
            width: 100%;
        }
    }
    .blog_text{
        padding-top: 36px;
        h5{
            font-size: 17px;
            color: $baseColor;
            font-family: $mon;
            font-weight: bold;
            letter-spacing: .34px;
        }
        h4{
            font-size: 22px;
            color: $dip;
            font-family: $mon;
            font-weight: bold;
            letter-spacing: .44px;
            padding: 9px 0px 20px 0px;
            border-bottom: 1px solid #e4e4e4;
            @include transition;
            &:hover{
                color: $baseColor;
            }
        }
        ul{
            padding-top: 14px;
            padding-bottom: 30px;
            li{
                display: inline-block;
                margin-right: 27px;
                a{
                    font-size: 17px;
                    color: #b8b8b8;
                    font-family: $lato;
                    font-weight: bold;
                    position: relative;
                    &:before{
                        content: "/";
                        position: absolute;
                        right: -17px;
                        top: 50%;
                        transform: translateY(-50%);
                        color: #b8b8b8;
                    }
                }
                &:last-child{
                    margin-right: 0px;
                    a{
                        &:before{
                            display: none;
                        }
                    }
                }
                &:hover{
                    a{
                        color: $baseColor;
                    }
                }
            }
        }
        p{
            font-size: 17px;
            font-family: $lato;
            color: $pfont;
            line-height: 28px;
            letter-spacing: .51px;
        }
    }
    .read_btn{
        margin-top: 30px;
    }
}

.blog_pagination{
    margin-top: 50px;
    ul{
        border-radius: 0px !important;
        margin-top: 0px;
        li{
            display: inline-block;
            margin-right: 7px;
            a{
                margin: 0px;
                padding: 0px;
                border: none;
                background: transparent;
                height: 44px;
                width: 44px;
                text-align: center;
                line-height: 40px;
                border: 2px solid #d4d4d4;
                color: $dip;
                font-size: 18px;
                border-radius: 0px !important;
                font-family: $open;
                font-weight: bold;
                @include transition;
            } 
            &:last-child{
                margin-right: 0px;
            }
            &:hover{
                a{
                    background: $baseColor;
                    color: #fff;
                    border-color: $baseColor;
                }
            }
        }
    }
}

.two_column_sample{
    overflow: hidden;
    padding-top: 45px;
    h3{
        font-size: 22px;
        font-family: $mon;
        font-weight: bold;
        color: $dip;
        padding-bottom: 25px;
    }
    .two_column_item{
        max-width: 370px;
        float: left;
        p{
            font-size: 17px;
            font-family: $lato;
            color: $pfont;
            line-height: 28px;
            letter-spacing: .51px;
        }
        &:last-child{
            margin-left: 30px;
        }
    }    
}
.s_main_text{
    padding-top: 50px;
    p{
        font-size: 16px;
        font-family: $mon;
        color: #8e8d8d;
        line-height: 26px;
        letter-spacing: .32px;
        a{
            color: #a175aa;
            text-decoration: underline;
        }
    }
}
.s_comment_list{
    margin-top: 30px;
    h3{
        font-size: 22px;
        color: $dip;
        font-family: $mon;
        letter-spacing: .44px;
        padding-bottom: 30px;
    }
}
.s_comment_list_inner{
    border: 1px solid #eeeeee;
    overflow: hidden;
    clear: both;
    .media{
        padding: 25px 60px 25px 25px;
        border-bottom: 1px solid #eeeeee;
        margin-top: 0px;
        &:nth-child(even){
            background: #fcfcfc;
        }
        &:last-child{
            border-bottom: none;
        }
        .media-left{
            padding-right: 30px;
        }
        .media-body{
            h4{
                font-size: 15px;
                font-family: $mon;
                font-weight: bold;
                letter-spacing: .30px;
                color: $dip;
            }
            p{
                font-size: 17px;
                line-height: 30px;
                color: #898989;
                letter-spacing: .34px;
                font-family: $lato;
                padding: 10px 0px;
            }
            .date_rep{
                a{
                    font-size: 15px;
                    color: #aeaeae;
                    font-family: $lato;
                    margin-right: 16px;
                    position: relative;
                    @include transition;
                    &:before{
                        content: "|";
                        color: #aeaeae;
                        position: absolute;
                        right: -12px;
                        top: 47%;
                        transform: translateY(-50%);
                    }
                    &:last-child{
                        margin-right: 0px;
                        &:before{
                            display: none;
                        }    
                    }
                    &:hover{
                        color: $baseColor;
                    }
                }
            }
            .media{
                background: transparent;
                padding-top: 50px;
                padding-bottom: 0px;
                padding-left: 0px;
                padding-right: 0px;
                .media-body{
                    
                }
            }
        }
    }
}
.s_comment_area{
    padding-top: 60px;
    h3{
        font-size: 22px;
        color: $dip;
        font-family: $mon;
        letter-spacing: .44px;
        padding-bottom: 30px;
    }
}
.s_comment_inner{
    background: #f7f7f7;
    border: 1px solid #eeeeee;
    padding: 50px 36px 45px 36px;
    .contact_us_form{
        .form-group{
            margin-bottom: 20px;
            &:last-child{
                margin-top: 10px;
                margin-bottom: 0px;
            }    
            input{
                border: 1px solid #eeeeee;
                padding: 0px 20px;
                font-size: 16px;
                font-family: $lato;
                color: #898989;
                background: #fff;
                @include placeholder{
                    font-size: 16px;
                    font-family: $lato;
                    color: #898989;
                }
            }
            textarea{
                border: 1px solid #eeeeee;
                padding: 18px 20px;
                font-size: 16px;
                font-family: $lato;
                color: #898989;
                height: 170px;
                background: #fff;
                @include placeholder{
                    font-size: 16px;
                    font-family: $lato;
                    color: #898989;
                }
            }
        }
    }
}

/* Sidebar Widget css */
.sidebar_area{
    
}
.r_widget{
    margin-bottom: 45px;
    &:last-child{
        margin-bottom: 0px;
    }
}
.r_widget_title{
    padding-bottom: 25px;
    h3{
        font-size: 22px;
        font-weight: bold;
        font-family: $mon;
        color: $dip;
        display: inline-block;
        position: relative;
        &:before{
            content: "";
            width: 35px;
            height: 3px;
            background: $baseColor;
            position: absolute;
            right: -45px;
            bottom: 5px;
        }
    }
}
.search_widget{
    .input-group{
        display: block;
        position: relative;
        width: 100%;
        height: 52px;
        input{
            height: 52px;
            border-radius: 0px;
            box-shadow: none;
            outline: none;
            font-size: 16px;
            color: #909090;
            font-family: $lato;
            letter-spacing: .48px;
            border: 1px solid #d3d3d3;
            padding: 0px 30px 0px 20px;
            @include placeholder{
                font-size: 16px;
                color: #909090;
                font-family: $lato;
                letter-spacing: .48px;
            }
            &:focus{
                border-color: $dip;
            }
        }
        .input-group-btn{
            position: absolute;
            right: 14px;
            top: 52%;
            transform: translateY(-50%);
            display: inline-block;
            width: auto;
            z-index: 5;
            .btn-default{
                padding: 0px;
                border: none;
                background: transparent;
                outline: none !important;
                box-shadow: none !important;
            }
        }
    }
}
.categories_widget{
    ul{
        li{
            a{
                display: block;
                font-size: 17px;
                font-family: $lato;
                font-weight: bold;
                color: $pfont;
                line-height: 42px;
                letter-spacing: .51px;
                border-bottom: 1px solid #e6e6e6;
                @include transition; 
                i{
                    float: right;
                    line-height: 42px;
                    position: relative;
                    @include transition;
                    right: 0px;
                }
            } 
            &:last-child{
                a{
                    border-bottom: 0px;
                }
            } 
            &:hover{
                a{
                    color: $baseColor;
                    i{
                        right: 5px;
                    }
                }
            }
        }
    }
}

.recent_widget{
    .recent_inner{
        .recent_item{
            padding: 18px 0px;
            border-bottom: 1px solid #e1e1e1;
            h4{
                font-size: 17px;
                font-family: $lato;
                font-weight: bold;
                letter-spacing: .34px;
                color: $dip;
                @include transition;
                &:hover{
                    color: $baseColor;
                    text-decoration: underline;
                }
            }
            h5{
                font-size: 15px;
                font-family: $lato;
                font-weight: bold;
                color: #9a9a9a;
                letter-spacing: .30px;
                padding-top: 12px;
            }
        }    
    }    
}
.tag_widget{
    ul{
        margin-right: -10px;
        margin-bottom: -10px;
        li{
            display: inline-block;
            margin-right: 7px;
            margin-bottom: 10px;
            a{
                display: inline-block;
                padding: 0px 12px;
                line-height: 36px;
                border: 1px solid #e4e4e4;
                font-size: 15px;
                font-family: $lato;
                color: #a6a6a6;
                @include transition;
            }
            &:hover{
                a{
                    background: $baseColor;
                    color: #fff;
                    border-color: $baseColor;
                }
            }
        }
    }    
}

/* End Blog Details Area css
============================================================================================ */



.blog_main_inner_two{
    margin-top: -80px;
    .blog_item{
        margin-top: 80px;
        .blog_text{
            h4{
                border: 0px;
            }
        } 
    } 
}

.pagination_two{
    background: #f5f5f5;
    padding: 50px 0px 43px;
    .blog_pagination{
        margin-top: 0px;
        .pagination{
            margin-bottom: 0px;
            width: 100%;
            text-align: center;
        }
    }
}


.latest_area_two{
    padding-top: 200px;
    margin-top: -90px;
}