/* Main Header Area css
============================================================================================ */
.main_menu_area{
    
}
.header_top{
    background: #2d2b34;
    padding-bottom: 28px;
    .pull-left{
        a{
            font-size: 16px;
            color: #b5b5b5;
            font-family: $mon;
            font-weight: 600;
            letter-spacing: .48px;
            text-transform: uppercase;
            margin-right: 60px;
            position: relative;
            line-height: 62px;
            display: inline-block;
            @include transition;
            &:before{
                content: "";
                height: 100%;
                width: 1px;
                background: #414047;
                position: absolute;
                right: -30px;
                top: 0px;
            }
            i{
                color: #96959a;
                font-size: 20px;
                line-height: 20px;
                padding-right: 20px;
                @include transition;
                &:before{
                    font-size: 20px;
                }
            }
            &:last-child{
                margin-right: 0px;
                i{
                    font-size: 25px;
                    line-height: 25px;
                    position: relative;
                    top: 2.5px;
                    &:before{
                        font-size: 25px;
                    }
                }
            }
            &:hover{
                color: $baseColor;
                i{
                    color: $baseColor;
                }
            }
        }
    }
    .pull-right{
        .header_social{
            display: inline-block;
            position: relative;
            margin-right: 30px;
            &:before{
                content: "";
                height: 15px;
                width: 1px;
                background: #636266;
                position: absolute;
                right: -31px;
                top: 50%;
                transform: translateY(-50%);
            }
            li{
                a{
                    line-height: 62px;
                }
            }
        }
        .btn-group.bootstrap-select{
            width: 87px !important;
            text-align: center;
            .dropdown-toggle{
                padding: 0px;
                background: transparent;
                border: none;
                color: rgba(255, 255, 255, .70);
                font-size: 16px;
                text-align: center;
                font-family: $pop;
                font-weight: 600;
                text-transform: uppercase;
                text-shadow: none;
                padding-left: 30px;
                outline: none !important;
                box-shadow: none !important;
                .bs-caret{
                    position: relative;
                    left: -9px;
                    top: -2px;
                    .caret{
                        border-top: 6px dashed #fff;
                        border-top: 6px solid #fff;
                        border-right: 6px solid transparent;
                        border-left: 6px solid transparent;
                    }
                }
            }
            .dropdown-menu{
                margin: 0px;
                padding: 0px;
                border: none;
                border-radius: 0px;
                top: calc(100% + 18px);
                li{
                    a{
                        padding: 5px 10px;
                    }
                }
            }
        }
    }
}
.header_social{
    li{
        display: inline-block;
        margin-right: 18px;
        a{
            font-size: 16px;
            color: rgba(255, 255, 255, .4);
            @include transition;
        }
        &:last-child{
            margin-right: 0px;
        }
        &:hover{
            a{
                color: $baseColor;
            }
        }
    }
}
#nav-icon1{
    width: 25px;
    display: inline-block;
    margin-left: 15px;
    position: relative;
    top: 2px;
    cursor: pointer;
    span{
        background: #fff;
        height: 2px;
        width: 100%;
        display: block;
        margin-bottom: 5px;
        &:last-child{
            margin-bottom: 0px;
        }
    }    
}

.navbar-default{
    .navbar-toggle{
        padding: 0px;
        border: 0px;
        margin-top: 26px;
        margin-bottom: 25px;
        .icon-bar{
            width: 30px;
            height: 3px;
            background: #fff;
            transform-origin: 2.5px center !important;
            transition: all 0.3s linear;
            &:nth-child(4){
                transform: rotate3d(0, 0, 1, -43deg);
                transform-origin: 50% 50%;
            }
            &:nth-child(2){               
                transform: rotate3d(0, 0, 1, 38deg);
                transform-origin: 50% 50%;
            }
            &:nth-child(3){
                opacity: 0;
                visibility: hidden;   
            }
            & + .icon-bar{
                margin-top: 5px;
            }
        }
        &:hover,&:focus{
            background: transparent;
        }
        &.collapsed{
            .icon-bar{
                &:nth-child(3){
                    opacity: 1;
                    visibility: visible;
                }
                &:nth-child(4),&:nth-child(2){
                    transform: rotate3d(0, 0, 0, 0deg);
                }
            }
        }
    }
}

/* End Main Header Area css
============================================================================================ */

/* Main Menu Area css
============================================================================================ */
.menu_inner_area{
    position: absolute;
    left: 0px;
    width: 100%;
    margin-top: -28px;
    z-index: 25;
    .navbar.navbar-default{
        margin-bottom: 0px;
        border-radius: 3px;
        border: none;
        background: #e2a062;
        text-align: right;
        .navbar-header{
            .navbar-brand{
                padding: 0px 30px;
                background: #e09254;
                border-top-left-radius: 3px;
                border-bottom-left-radius: 3px;
                min-height: 86px;
                position: relative;
                line-height: 80px;
                img{
                    display: inline-block;
                }
                &:before{
                    content: "";
                    right: -62px;
                    top: 0px;
                    position: absolute;
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: 86px 62px 0 0;
                    border-color: #e09254 transparent transparent transparent;
                    position: absolute;
                }
            }
        } 
        .navbar-collapse{
        }
        .nav.navbar-nav{
            float: none;
            display: inline-block;
            li{
                float: none;
                margin-right: 30px;
                display: inline-block;
                a{
                    padding: 0px;
                    line-height: 86px;
                    background: transparent;
                    font-size: 18px;
                    font-family: $pop;
                    color: #fff;
                    font-weight: 500;
                    position: relative;
                    display: inline-block;
                    @include transition;
                    &:before{
                        content: "";
                        width: 52%;
                        height: 2px;
                        position: absolute;
                        bottom: 25px;
                        background: #302f37;
                        opacity: 0;
                        @include transition;
                    }
                }
                &:last-child{
                    margin-right: 0px;
                }
                &:hover, &.active{
                    a{
                        color: #302f37;
                        &:before{
                            opacity: 1;
                        }
                    }
                }
                &.submenu{
                    .dropdown-menu{
                        @media(min-width: 992px){
                            transform: scaleY(0);
                            @include transition;
                            display: block;
                            transform-origin: 50% 0;
                            min-width: 246px;
                            background: #fff;
                            border-radius: 0px;
                            border: none;
                            padding: 25px 0px 30px 0px;
                            box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
                        }
                        li{
                            display: block;
                            margin-right: 0px;    
                            a{
                                padding: 0px 25px;
                                line-height: 50px;
                                font-family: $pop;
                                font-weight: 500;
                                font-size: 15px;
                                text-shadow: none;
                                line-height: 42px;
                                color: $dip;
                                @include transition;
                                text-transform: capitalize;
                                &:before{
                                    display: none;
                                }
                            }
                            &:hover{
                                a{
                                    color: $baseColor;  
                                }
                            }
                        }
                    }
                }
                &:hover{
                    @media(min-width: 992px){
                        &.submenu{
                            .dropdown-menu{
                                transform: scaleY(1);
                                transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
                            }
                        }
                    }
                }
            }
        }
        .nav.navbar-nav.navbar-right{
            li{
                margin-left: 50px;
                margin-right: 30px;
                a{
                    position: relative;
                    &:after{
                        content: "";
                        height: 15px;
                        width: 1px;
                        background: #f3d9c0;
                        position: absolute;
                        left: -25px;
                        top: 50%;
                        transform: translateY(-50%);
                    }
                    &:before{
                        display: none;
                    }
                }
            }
        }
    }
    &.affix{
        position: fixed;
        top: 0;
        left: 0;
        z-index: 999;
        background: #e2a062;
        width: 100%;
        margin-top: 0px;
        -webkit-backface-visibility:hidden;
        .navbar{
            border-radius: 0px;
            .navbar-header{
                .navbar-brand{
                    min-height: 70px;
                    line-height: 70px;
                    padding-left: 0px;
                    &:before{
                        border-width: 70px 62px 0 0;
                    }
                    &:after{
                        content: "";
                        background: #e09254;
                        position: absolute;
                        right: 0;
                        width: 500%;
                        height: 100%;
                        top: 0;
                        z-index: -1;
                    }
                }
            } 
            .nav.navbar-nav li a{
                line-height: 70px;
                &:before{
                    bottom: 18px;
                }
            }
        }
    }
}
/* End Main Menu Area css
============================================================================================ */


.main_menu_area_two{
    .header_top{
        padding-bottom: 0px;
    }   
    .menu_inner_area{
        margin-top: 0px;
        .navbar.navbar-default{
            background: transparent;
            .navbar-header{
                .navbar-brand{
                    background: transparent;
                    padding-left: 0px;
                    &:before{
                        display: none;
                    }
                    img{
                        & + img{
                            display: none;
                        }
                    }
                } 
                .navbar-toggle{
                    margin-right: 0px;
                }
            }
            .nav.navbar-nav{
                li{
                    &.active,&:hover{
                        a{
                            color: $baseColor;
                            &:before{
                                background: $baseColor;
                            }
                        }
                    }
                }
            }
            .nav.navbar-nav.navbar-right{
                li{
                    margin-right: 0px; 
                }
            } 
        }
        &.affix{
            position: fixed;
            width: 100%;
            background: #fff;
            top: 0;
            left: 0;
            z-index: 999;
            box-shadow: 0px 4px 7px 0px rgba(0, 0, 0, 0.08);
            -webkit-backface-visibility:hidden;
            .navbar{
               .navbar-header{
                    .navbar-brand{
                        min-height: 70px;
                        line-height: 68px;
                        &:after{
                            display: none;
                        }
                        img{
                            display: none;
                            & + img{
                                display: inline-block;
                            }
                        }
                    }
                } 
                .nav.navbar-nav{
                    li{
                        a{
                            color: #242424;
                            line-height: 70px;
                            &:before{
                                bottom: 18px;
                            }
                        }
                    }
                    &.navbar-right li a:after{
                        background: #7c7c7c;
                    }
                }
                .navbar-toggle .icon-bar{
                    background: #242424;
                }
            }
        }
    }
}

/* Start main_menu_area_three css
============================================================================================*/
.main_menu_area_three{
    position: absolute;
    top: 0;
    width: 100%;
    left: 0;
    z-index: 25;
    .header_top{
        padding-bottom: 0px;
        .pull-left{
            a{
                font: 500 15px/50px $pop;
                color: #98979a;
                vertical-align: middle;
                display: inline-block;
                &:before{
                    display: none;
                }
                i{
                    font-size: 20px;
                    padding-right: 12px;
                }
                &:hover{
                    color: #e2a062;
                }
            }
        }
        .pull-right{
            .header_social{
                li{
                    a{
                        line-height: 50px;
                    }
                }
            }
        } 
    }
}
.header_logo_area{
    padding: 25px 0px;
    .logo{
        line-height: 50px;
        display: block;
    }
    .pull-right{
        line-height: 0px;
        .media{
            display: inline-block;
            margin-top: 0px;
            margin-right: 40px;
            .media-left{
                padding-right: 20px;
                i{
                    font-size: 25px;
                    line-height: 50px;
                    color: #e2a062;
                }
            }
            .media-body{
                width: auto;
                h4{
                    font-size: 15px;
                    font-weight: 600;
                    line-height:22px;
                    color: #333333;
                    font-family: $pop;
                }
                p{
                    font-size: 14px;
                    color: #767676;
                    font-weight: 600;
                    line-height: 22px;
                    font-family: $pop;
                }
            }
            &:last-child{
                margin-right: 0px;
            }
        }
    }
}

.menu{
    li{
        a{
            font: 500 18px/62px $pop;
            padding: 0px;
            transition: all 0.3s linear;
        }
    }
}
.main_menu_bg{
    .navbar{
        width: calc(50% + 585px);
        background: #38373d;
        float: right;
        margin-bottom: 0px;
        border-radius: 0px;
        border: 0px;
        min-height: auto;
        .navbar-brand{
            display: none;
        }
        .navbar-collapse{
            max-width: 1170px;
            margin: 0px auto 0px 0px;
            padding: 0px;
            .menu{
                padding: 0px 60px 0px 35px;
                background: $baseColor;
                li{
                    margin-right: 50px;
                    a{
                        background: transparent; 
                        color: #fff;
                    }
                    &:last-child{
                        margin-right: 0px;
                    }
                    &.search{
                        a{
                            &:after{
                                content: "";
                                height: 15px;
                                width: 1px;
                                background: #f3d9c0;
                                position: absolute;
                                left: -25px;
                                top: 50%;
                                -webkit-transform: translateY(-50%);
                                -ms-transform: translateY(-50%);
                                transform: translateY(-50%);
                            }
                        }
                    }
                    &:hover, &.active{
                        a{
                            color: #302f37;
                            &:before{
                                opacity: 1;
                            }
                        }
                    }
                    &.submenu{
                        .dropdown-menu{
                            @media(min-width: 992px){
                                transform: scaleY(0);
                                @include transition;
                                display: block;
                                transform-origin: 50% 0;
                                min-width: 246px;
                                background: #fff;
                                border-radius: 0px;
                                border: none;
                                padding: 25px 0px 27px 0px;
                                box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
                            }
                            li{
                                margin-right: 0px;
                                display: block;
                                a{
                                    padding: 0px 25px;
                                    line-height: 50px;
                                    font-family: $pop;
                                    font-weight: 500;
                                    font-size: 15px;
                                    text-shadow: none;
                                    line-height: 42px;
                                    color: $dip;
                                    @include transition;
                                    text-transform: capitalize;
                                    &:before{
                                        display: none;
                                    }
                                }
                                &:hover{
                                    a{
                                        color: $baseColor;
                                        
                                    }
                                }
                            }
                        }
                    }
                    &:hover{
                        @media(min-width: 992px){
                            &.submenu{
                                .dropdown-menu{
                                    transform: scaleY(1);
                                    transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
                                }
                            }
                        }
                    }
                }
            }
            .navbar-right{
                margin-right: 0px;
                li{
                    a{
                        font: 600 26px/62px $mon;
                        color: $baseColor;
                        padding: 0px;
                    }
                }
            }
        }
    }
    &.affix{
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        z-index: 999;
        -webkit-backface-visibility:hidden;
    }
}
/* End main_menu_area_three css
============================================================================================*/

.main_menu_area_four{
    background: #1c1c1c;
    position: absolute;
    top: 0;
    width: 100%;
    left: 0;
    z-index: 22;
    .header_logo_area_two{
        padding: 20px 0px;
        .logo{
            display: block;
            line-height: 50px;
        }
        .top-two-right{
            .top-contact-info{
                li{
                    a{
                        color: #b5b5b5;
                        i{
                            color: #8e8e8e;
                        }
                        &:hover{
                            color: $baseColor;
                            i{
                                color: $baseColor; 
                            }
                        }
                    }
                    & + li{
                        border-color: #454341;
                    }
                    
                }
            }
        } 
    }
    & + section{
        margin-top: 153px;
    }
}
.menu_inner_area_two{
    .navbar{
        margin-bottom: 0px;
        border: 0px;
        border-top: 1px solid #303030;
        border-radius: 0px;
        background: transparent;
        min-height: auto;
        position: relative;
        &:before{
            content: "";
            width: 100%;
            height: 1px;
            top: -2px;
            left: 0;
            background: #000;
            position: absolute;
        }
        .navbar-header{
            display: none;
        }
        .navbar-collapse{
            .menu{
                li{
                    margin-right: 58px;
                    a{
                        color: #fff;
                        background: transparent;
                    }
                    &.search{
                        a{
                            &:after{
                                content: "";
                                height: 15px;
                                width: 1px;
                                background: #a4a4a4;
                                position: absolute;
                                left: -25px;
                                top: 50%;
                                -webkit-transform: translateY(-50%);
                                -ms-transform: translateY(-50%);
                                transform: translateY(-50%);
                            }
                        }
                    }
                    &:last-child{
                        margin-right: 0px;
                    }
                    &:hover, &.active{
                        a{
                            color: $baseColor;
                            &:before{
                                opacity: 1;
                            }
                        }
                    }
                    &.submenu{
                        .dropdown-menu{
                            @media(min-width: 992px){
                                transform: scaleY(0);
                                @include transition;
                                display: block;
                                transform-origin: 50% 0;
                                min-width: 246px;
                                background: #fff;
                                border-radius: 0px;
                                border: none;
                                padding: 25px 0px 27px 0px;
                                box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
                            }
                            li{
                                margin-right: 0px;
                                display: block;
                                margin-right: 0px;
                                a{
                                    padding: 0px 25px;
                                    line-height: 50px;
                                    font-family: $pop;
                                    font-weight: 500;
                                    font-size: 15px;
                                    text-shadow: none;
                                    line-height: 42px;
                                    color: $dip;
                                    @include transition;
                                    text-transform: capitalize;
                                    &:before{
                                        display: none;
                                    }
                                }
                                &:hover{
                                    a{
                                        color: $baseColor;
                                        
                                    }
                                }
                            }
                        }
                    }
                    &:hover{
                        @media(min-width: 992px){
                            &.submenu{
                                .dropdown-menu{
                                    transform: scaleY(1);
                                    transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
                                }
                            }
                        }
                    }
                }
            }
        }
        .social-nav{
            margin-right: 0px;
            li{
                margin-left: 20px;
                a{
                    font-size: 18px;
                    line-height: 62px;
                    color: $baseColor;
                    padding: 0px;
                }
            }
        }
    }
    &.affix{
        position: fixed;
        top: 0;
        left: 0;
        z-index: 999;
        background: #1c1c1c;
        width: 100%;
        -webkit-backface-visibility:hidden;
    }
}


/* Start main_menu_area_five css
============================================================================================*/
.main_menu_area_five{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 22;
    .menu_inner_area_two{
        background: #2d2b34;
        .navbar{
            border-top: 0px;
            &:before{
                display: none;
            }
            .navbar-collapse{
                .menu{
                    li{
                        margin-right: 62px;
                        a{
                            &:after{
                                content: "";
                                height: 25px;
                                width: 1px;
                                background: #4a4850;
                                position: absolute;
                                left: -31px;
                                top: 50%;
                                -webkit-transform: translateY(-50%);
                                -ms-transform: translateY(-50%);
                                transform: translateY(-50%);
                            }
                        }
                        &:first-child{
                            a{
                                &:after{
                                    display: none;
                                }
                            }
                        }
                        &.submenu{
                            .dropdown-menu{
                                li{
                                    a{
                                        &:after{
                                            display: none;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    & +section{
        margin-top: 162px;
    }
}

.top-two-right{
    overflow: hidden;
    .learn_btn{
        float: right;
        letter-spacing: 0.48px;
        padding: 0px 27px;
        margin-left: 10px;
    }
    .top-contact-info{
        float: left;
        width: 100%;
        li{
            display: inline-block;
            a{
                font: 600 16px/30px $mon;
                color: $dip;
                padding: 0px 20px;
                transition: all 0.3s linear;
                text-transform: uppercase;
                i{
                    color: #96959a;
                    font-size: 20px;
                    line-height: 20px;
                    padding-right: 20px;
                }
                &:hover{
                    background: transparent;
                    color: #e2a062;
                    i{
                        color: #e2a062;
                    }
                }
            }
            &:first-child{
                a{
                    padding-left: 0px;
                }
            }
            & + li{
                border-left:1px solid #b6b4b2;
                a{
                    i{
                        font-size: 25px;
                        line-height: 25px;
                    }
                }
            }
        }
    }
    .top-panel{
        float: right;
        padding: 10px 0px 9px;
    }
}

/* End main_menu_area_five css
============================================================================================*/




/* side navigation css
==========================================================================================*/

.right_sidebar_menu {
    position: fixed;
    right: 0px;
    top: 0px;
    z-index: 999;
    max-width: 510px;
    width: 100%;
    min-height: 100vh;
    -webkit-transition: all 300ms linear;
    -o-transition: all 300ms linear;
    transition: all 300ms linear;
    background: #323038;
    -webkit-transform: translate3d(510px, 0, 0);
    transform: translate3d(510px, 0, 0);
    .close_menu {
        font-size: 25px;
        text-transform: uppercase;
        color: #fff;
        cursor: pointer;
        text-align: right;
        position: absolute;
        right: 0px;
        height: 75px;
        width: 75px;
        top: 0px;
        span {
            width: 25px;
            height: 1px;
            background: #fff;
            display: inline-block;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
            position: absolute;
            top: 38px;
            left: 23px;
            &:nth-child(2){
                transform: rotate(-45deg);
            }
        }
        &:hover{
            span{
                background: $baseColor;
            }
        }
    }
    .right_widget_inner{
        max-height: 100%;
        width: 435px;
        background: #39383d;
        position: absolute;
        padding: 100px 60px;
        border-right: 1px solid #4a4a4e;
        overflow-y: scroll;
        bottom: 0;
        top: 0;
        .appointment_widget{
            opacity: 0;
            transform: translateY(3rem);
            transition: transform 0.6s 0.4s ease, opacity 0.6s 0.4s ease, color 0.6s ease;
            transition: transform 0.6s 0.4s ease, opacity 0.6s 0.4s ease, color 0.6s ease, -webkit-transform 0.6s 0.4s ease;
            h2{
                font: 700 24px/26px $mon;
                color: #fff;
                padding-bottom: 20px;
            }
            .contact_us_form{
                .form-group{
                    padding: 0px;
                    margin-bottom: 15px; 
                    .form-control{
                        border: 1px solid #56565a;
                        height: 45px;
                        padding: 0px 22px;
                        font: 400 15px/26px $mon;
                        color: #bdbdbd;
                        @include placeholder{
                            color: #bdbdbd;
                        }
                    }
                    textarea{
                        &.form-control{
                            height: 134px;
                            padding-top: 14px;
                        }
                    }
                    .submit_btn{
                        margin-top: 15px;
                    }
                }
            }
        }
        .contact_widget{
            margin-top: 200px;
            opacity: 0;
            transform: translateY(8rem);
            transition: transform 2s 0.8s ease, opacity 1s 0.8s ease, color 0.6s ease;
            transition: transform 2s 0.8s ease, opacity 1s 0.8s ease, color 0.6s ease, -webkit-transform 2s 0.8s ease;
            .w_contact{
                position: relative;
                padding-bottom: 20px;
                margin-bottom: 32px;
                &:before{
                    content: "";
                    width: 50px;
                    height: 1px;
                    background: #fff;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                }
                a{
                    display: block;
                    font: 400 18px/26px $mon;
                    color: #fff;
                }
            }
            .social_list{
                li{
                    width: 50%;
                    display: inline-block;
                    a{
                        font: 400 16px/28px $lato;
                        color: #929292;
                        letter-spacing: 0.32px;
                        transition: all 0.3s linear;
                        &:hover{
                            color: $baseColor;
                        }
                    }
                    &:nth-child(even){
                        float: right;
                    }
                }
            }
        }
    }
    &.open{
        transform: translate3d(0px, 0, 0);
        .contact_widget,.appointment_widget{
            opacity: 1;
            transform: translateY(0);
        }
    }
}