/*font Variables*/
$mon: 'Montserrat', sans-serif;
$lato: 'Lato', sans-serif;
$pop: 'Poppins', sans-serif;
$open: 'Open Sans', sans-serif;
/*Color Variables*/

$baseColor: #e2a062;
$baseColorh: #c2874e;
$dip: #242424;
//$pfont: #797979;
$pfont: #4a4a4a;



// Mixins
@mixin transition($property: all, $duration: 300ms, $animate: linear, $delay:0s){
    transition: $property $duration $animate $delay;
}

// Placeholder Mixins
@mixin placeholder {
  &.placeholder { @content; }
  &:-moz-placeholder { @content; }
  &::-moz-placeholder { @content; }
  &::-webkit-input-placeholder { @content; }
}

.col-md-offset-right-1,.col-lg-offset-right-1{
    margin-right: 8.33333333%;
}

// Font family link
//@import url('https://fonts.googleapis.com/css?family=Lato:300,400,400i,700,900|Montserrat:300,400,500,600,700|Open+Sans:300,400,600,700|Poppins:300,400,500,600,700');

///* lato-300 - latin */
@font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 300;
    src: local(''), url('../Fonts/theme-font/lato-v23-latin-300.woff2') format('woff2'), url('../Fonts/theme-font/lato-v23-latin-300.woff') format('woff');
}

///* lato-regular - latin */
@font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    src: local(''), url('../Fonts/theme-font/lato-v23-latin-regular.woff2') format('woff2'), url('../Fonts/theme-font/lato-v23-latin-regular.woff') format('woff');
}

///* lato-italic - latin */
@font-face {
    font-family: 'Lato';
    font-style: italic;
    font-weight: 400;
    src: local(''), url('../Fonts/theme-font/lato-v23-latin-italic.woff2') format('woff2'), url('../Fonts/theme-font/lato-v23-latin-italic.woff') format('woff');
}

///* lato-700 - latin */
@font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    src: local(''), url('../Fonts/theme-font/lato-v23-latin-700.woff2') format('woff2'), url('../Fonts/theme-font/lato-v23-latin-700.woff') format('woff');
}

///* lato-900 - latin */
@font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 900;
    src: local(''), url('../Fonts/theme-font/lato-v23-latin-900.woff2') format('woff2'),  url('../Fonts/theme-font/lato-v23-latin-900.woff') format('woff');
}

///* montserrat-300 - latin */
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 300;
    src: local(''), url('../Fonts/theme-font/montserrat-v25-latin-300.woff2') format('woff2'), url('../Fonts/theme-font/montserrat-v25-latin-300.woff') format('woff');
}

///* montserrat-regular - latin */
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    src: local(''), url('../Fonts/theme-font/montserrat-v25-latin-regular.woff2') format('woff2'), url('../Fonts/theme-font/montserrat-v25-latin-regular.woff') format('woff');
}

///* montserrat-500 - latin */
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    src: local(''), url('../Fonts/theme-font/montserrat-v25-latin-500.woff2') format('woff2'), url('../Fonts/theme-font/montserrat-v25-latin-500.woff') format('woff');
}

///* montserrat-600 - latin */
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    src: local(''), url('../Fonts/theme-font/montserrat-v25-latin-600.woff2') format('woff2'), url('../Fonts/theme-font/montserrat-v25-latin-600.woff') format('woff');
}

///* montserrat-700 - latin */
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    src: local(''), url('../Fonts/theme-font/montserrat-v25-latin-700.woff2') format('woff2'), url('../Fonts/theme-font/montserrat-v25-latin-700.woff') format('woff');
}

///* open-sans-300 - latin */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 300;
    src: local(''), url('../Fonts/theme-font/open-sans-v34-latin-300.woff2') format('woff2'), url('../Fonts/theme-font/open-sans-v34-latin-300.woff') format('woff');
}

///* open-sans-regular - latin */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    src: local(''), url('../Fonts/theme-font/open-sans-v34-latin-regular.woff2') format('woff2'), url('../Fonts/theme-font/open-sans-v34-latin-regular.woff') format('woff');
}

///* open-sans-600 - latin */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    src: local(''), url('../Fonts/theme-font/open-sans-v34-latin-600.woff2') format('woff2'), url('../Fonts/theme-font/open-sans-v34-latin-600.woff') format('woff');
}

///* open-sans-700 - latin */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    src: local(''), url('../Fonts/theme-font/open-sans-v34-latin-700.woff2') format('woff2'), url('../Fonts/theme-font/open-sans-v34-latin-700.woff') format('woff');
}

///* poppins-300 - latin */
@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    src: local(''), url('../Fonts/theme-font/poppins-v20-latin-300.woff2') format('woff2'), url('../Fonts/theme-font/poppins-v20-latin-300.woff') format('woff');
}

///* poppins-regular - latin */
@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    src: local(''), url('../Fonts/theme-font/poppins-v20-latin-regular.woff2') format('woff2'), url('../Fonts/theme-font/poppins-v20-latin-regular.woff') format('woff');
}

///* poppins-500 - latin */
@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    src: local(''), url('../Fonts/theme-font/poppins-v20-latin-500.woff2') format('woff2'), url('../Fonts/theme-font/poppins-v20-latin-500.woff') format('woff');
}

///* poppins-600 - latin */
@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    src: local(''), url('../Fonts/theme-font/poppins-v20-latin-600.woff2') format('woff2'), url('../Fonts/theme-font/poppins-v20-latin-600.woff') format('woff');
}

///* poppins-700 - latin */
@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    src: local(''), url('../Fonts/theme-font/poppins-v20-latin-700.woff2') format('woff2'), url('../Fonts/theme-font/poppins-v20-latin-700.woff') format('woff');
}

@font-face {
    font-family: "ralH";
    src: url('../Fonts/theme-font/Arial.ttf.woff');
}
