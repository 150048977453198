.partner-logo .partner-logo-item .media-body h3 {
    font: 700 20px/28px $mon;
    color: #242424;
    letter-spacing: 0.40px;
    text-transform: uppercase;
    padding-bottom: 8px;
}

.advisor_inner {
    .practice_item {
        margin-bottom: 2rem;
        .p_img_inner img {
            height: auto;
        }
        .p_hover li a {
            font-size: 40px;
        }
    }
}

.cases_item {
    border: 1px solid lighten($pfont, 30);
    margin-bottom: 1rem;
    min-height: 200px;
    .cases_image {
        padding: 1rem;
        img {
            max-width: 100%;
        }
    }
    h5 {
        color: $pfont;
        font-size: 1rem;
    }
}

.since_area .bcg {
    background-image: none;
}
