.main-content {
    padding-bottom: 5rem;

    #page1 &{
        padding-bottom: 0;
    }
}

.container .container {
    width: auto;
}

@media (min-width: 991px) {
    .sidebar {
        padding-top: 10rem;
    }
}
.frame-default {
    //@extend .pad_100;
}

.frame-pad {
    @extend .pad_100;
}

.frame-padTop {
    @extend .pad_100;
    padding-bottom: 0;
}

.frame-padBottom {
    @extend .pad_100;
    padding-top: 0;
}

//li:not(.p_hover li) {
//    font: 400 17px/22px $lato;
//    padding-bottom: 12px;
//    color: #797979;
//    letter-spacing: 0.34px;
//    //display: inline-block;
//    display: block;
//    position: relative;
//    padding-left: 35px;
//    transition: all 0.3s linear;
//    i {
//        position: absolute;
//        left: 0;
//        width: 16px;
//        height: 16px;
//        border: 1px solid #797979;
//        border-radius: 50%;
//        text-align: center;
//        line-height: 13px;
//        color: #a6cde7;
//        font-size: 10px;
//        top: 3px;
//    }
//    &:hover {
//        color: #e2a062;
//    }
//}

.shariff {
    margin-top: 5rem;
}

// Footer
.about_widget h4 a {
    color: #e2a062;
    font-size: 15px;
}

.footer_widget_area {
    a {
        color: rgba(255,255,255,0.6);
    }
}
