/* Banner Area css
============================================================================================ */
.banner_area{
    background: url(/fileadmin/pics/slider/FBSA-gefecht.jpg) no-repeat scroll center center;
    background-size: cover;
    position: relative;
    z-index: 3;
    &:before{
        content: "";
        background: rgba(0, 0, 0, .58);
        position: absolute;
        left: 0px;
        top: 0px;
        height: 100%;
        width: 100%;
        z-index: -1;
    }
    .banner_inner{
        padding-top: 150px;
        padding-bottom: 90px;
        text-align: center;
        h2{
            font-size: 48px;
            color: #fff;
            font-family: $mon;
            font-weight: bold;
            letter-spacing: .96px;
            padding-bottom: 14px;
        }
        ul{
            li{
                display: inline-block;
                margin-right: 22px;
                a{
                    font-size: 16px;
                    color: #fff;
                    letter-spacing: .32px;
                    position: relative;
                    @include transition;
                    &:before{
                        content: "\f105";
                        font: normal normal normal 14px/1 FontAwesome;
                        position: absolute;
                        right: -16px;
                        top: 50%;
                        transform: translateY(-50%);
                        color: #fff;
                    }
                }
                &:last-child{
                    margin-right: 0px;
                    a{
                        &:before{
                            display: none;
                        }
                    }
                }
                &:hover, &.active{
                    a{
                        color: $baseColor;
                    }
                }
            }
        }
    }
}
/* End Banner Area css
============================================================================================ */

