/* Querry Contact Area css
============================================================================================ */
.footer_widget_area{
    background: #353637;
    padding: 80px 0px;
}
.f_widget_inner{

}
.f_widget{

}
.f_title{
    padding-bottom: 25px;
    h3{
        font-size: 16px;
        color: #fff;
        font-family: $mon;
        font-weight: 500;
        letter-spacing: .32px;
        text-transform: uppercase;
    }
}
.about_widget{
    img{
        max-width: 100%;
    }
    h4{
        font-size: 18px;
        color: $baseColor;
        font-family: $mon;
        letter-spacing: .36px;
        padding-top: 32px;
    }
    h5{
        font-size: 15px;
        color: #bfc0c0;
        font-family: $mon;
        letter-spacing: .30px;
        padding: 8px 0px 40px 0px;
    }
    p{
        font-size: 15px;
        color: $pfont;
        line-height: 21px;
        letter-spacing: .28px;
    }
}
.link_widget{
    ul{
        margin-top: -4px;
        li{
            a{
                font-size: 15px;
                line-height: 36px;
                font-family: $mon;
                color: rgba(255, 255, 255, .6);
                @include transition;
            }
            &:hover{
                a{
                    color: $baseColor;
                }
            }
        }
    }
    &.link_widget_two{
        margin-left: -30px;
    }
}

.subscrib_widget{
    padding-left: 15px;
    .mailchimp{
        .subscrib_form{
            display: block;
            input{
                display: block;
                border: 2px solid #4a4a4b;
                background: transparent;
                border-radius: 0px;
                height: 55px;
                padding: 0px 20px;
                outline: none;
                box-shadow: none;
                font-size: 15px;
                color: rgba(255, 255, 255, .5);
                letter-spacing: .30px;
                font-family: $mon;
                margin-bottom: 30px;
                @include placeholder{
                    font-size: 15px;
                    color: rgba(255, 255, 255, .5);
                    letter-spacing: .30px;
                    font-family: $mon;
                }
            }
        }
    }
}
/* Querry Contact Area css
============================================================================================ */
