.client_slider {
    @extend .client_slider;
    padding: 2rem 0;

    img {
        //filter: grayscale(100%);
    }
}

.blog_item .blog_img img {
    height: auto;
}
