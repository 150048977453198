// FBSA
// make images darker:
.slotholder::before {
    content: "";
    background: rgba(0, 0, 0, .58);
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: +1;
}
