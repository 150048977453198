
.ce-textpic {

    [data-ce-images="1"][data-ce-columns="2"] {
        width: 100%;
    }
    [data-ce-images="1"][data-ce-columns="3"] {
        width: 100%;
    }

    // multiple images in columns
    //[data-ce-images="2"][data-ce-columns="2"] .csc-textpic-imagecolumn,
    //[data-ce-images="3"][data-ce-columns="3"] .csc-textpic-imagecolumn,
    //[data-ce-images="5"][data-ce-columns="5"] .csc-textpic-imagecolumn {
    //    width: 100%;
    //    margin-right: 0;
    //    margin-bottom: 1%;
    //}

            // test
    .ce-gallery {
        .ce-row {
            display: flex;
        }
        &[data-ce-columns="1"] {
            flex-direction: column;
            .ce-column {
                margin-right: 0;
            }
        }
    }

    @media (min-width:768px) {
        .ce-gallery[data-ce-images="1"][data-ce-columns="2"] {
            width: 49%;
            margin-right: 2%;
            &:last-child {
                margin-right: 0;
            }
        }
        .ce-gallery[data-ce-images="1"][data-ce-columns="3"] {
            width: 33%;
        }
        // 2 images
        .ce-gallery[data-ce-images="2"][data-ce-columns="2"] .ce-column {
            width: 48%;
            margin-right: 2%;
            &:last-child {
                margin-right: 0;
            }
        }
        [data-ce-images="2"][data-ce-columns="3"] .ce-column {
            width: 33%;
        }
        [data-ce-images="2"][data-ce-columns="4"] .ce-column {
            width: 25%;
        }
        // 3 images
        [data-ce-images="3"][data-ce-columns="3"] .ce-column {
            width: 32.33333333%;
            margin-right: 1%;
            &:last-child {
                margin-right: 0;
            }
        }
        [data-ce-images="3"][data-ce-columns="4"] .ce-column {
            width: 25%;
            margin-right: 1%;
            &:last-child {
                margin-right: 0;
            }
        }
        // 4 images
        [data-ce-images="4"][data-ce-columns="2"] .ce-column {
            width: 50%;
        }
        [data-ce-images="4"][data-ce-columns="4"] .ce-column {
            width: 25%;
        }

        // 8 images
        [data-ce-images="8"][data-ce-columns="4"] .ce-column {
            width: 25%;
        }

            // test
            .ce-gallery .ce-row {
                    display: flex;
                    .ce-column {
                            margin-right: 1rem;
                    }
            }
    }
}

.ce-left.ce-intext .ce-gallery {
    width: auto;
    .ce-row {
        display: inline;
    }
}

.ce-border img, .ce-border iframe {
    border: 1px solid #999;
}

.img-copyright {
    font-size: 1rem;
    //text-align: right;
    margin: -2.5rem 0 0 0;
    bottom: 0;
    display: inline-block;
    padding: 0 .4rem;
    color: #000;
    background: rgba(175, 175, 175, 0.7);

    .news-list-view & {
        position: absolute;
    }
}

.image-caption, .news-img-caption {
    font-style: italic;
    font-size: 1.2rem;
    line-height: 1.3;
    padding-top: .5rem;
    margin-bottom: 1rem;
}

//.video-embed {
//    position: relative;
//    padding-bottom: 55%;
//    padding-top: 15px;
//    height: 0;
//    overflow: hidden;
//}
//.video-embed iframe {
//    position: absolute;
//    top: 0;
//    left: 0;
//    width: 100%;
//    height: 100%;
//}
